import { PROFILE } from './constants';
import { RewardsSection } from './RewardSection';
import { MaintenanceView } from '../MaintenanceView';
import { AuthGuard } from '../auth-guard';

export function Rewards({ underMaintenance }) {
  if (underMaintenance) {
    return <MaintenanceView />;
  }

  return (
    <AuthGuard>
      <div className="flex flex-col gap-y-20 pb-6">
        <RewardsSection
          title="Promotion"
          description="Promotion to earn rewards"
          type={PROFILE}
        />
      </div>
    </AuthGuard>
  );
}
