import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { FormattedNumber } from 'react-intl';
import clsx from 'clsx';
import { useState } from 'react';
import { CircularProgress } from '@mui/material';
import { PaymentButton } from './PaymentButton/PaymentButton';
import { PrimaryDarkButton } from '../../../ui';
import { BWJ_TOKEN, PROFILE, WUSD_TOKEN } from '../../constants';
import { TweetModal } from '../TweetModal/TweetModal';

export function ClaimAll({
  rewardsQuery,
  type,
  onClaimClick,
  claimDisabled,
  paymentToken,
  setPaymentToken,
  isLoadingAnyState,
  className,
}) {
  const [openTweetModal, setOpenTweetModal] = useState(false);
  const bwjAmount = rewardsQuery?.data?.totalAmount;
  const wusdAmount = rewardsQuery?.data?.totalAmount * 0.000014;
  const bwjClaimLimit = rewardsQuery?.data?.atLeastToClaim;
  const lowerThanClaimLimit = bwjAmount < bwjClaimLimit;
  const noRewards = rewardsQuery?.data?.items.length === 0;

  return (
    <>
      <div
        className={clsx(
          'overflow-auto bg-tertiary-darker/70 p-4 sm:p-5 rounded-xl flex flex-col gap-y-6 h-full',
          className
        )}
      >
        <h2 className="uppercase font-extrabold text-2xl [text-shadow:_0_8px_2px_rgb(0_0_0_/_60%)] tracking-wider z-10 flex-col flex italic">
          <span>Claim </span>
          {type === PROFILE && (
            <span className="flex items-center gap-x-2">
              Promotion
              <DoubleArrowIcon className="-skew-x-3 -translate-x-1 w-7 h-7 opacity-70" />
            </span>
          )}
          <span>Rewards</span>
        </h2>

        <div className="flex items-center gap-x-2 sm:gap-x-3">
          <PaymentButton
            onClick={() => setPaymentToken(WUSD_TOKEN)}
            tokenName={'WPoint'}
            isSelected={paymentToken === WUSD_TOKEN}
            wusdAmount={wusdAmount}
          />
          <div className="w-1/2" />
        </div>
        <div className="flex flex-col gap-y-1">
          {paymentToken === BWJ_TOKEN && (
            <span className="text-xs opacity-60 mb-1.5 text-center">
              You need at least <FormattedNumber value={bwjClaimLimit} /> BWJ to
              claim this
            </span>
          )}
          <PrimaryDarkButton
            className="w-full text-sm font-bold sm:text-base tracking-wide flex items-center gap-x-1 whitespace-nowrap"
            onClick={onClaimClick}
            disabled={
              claimDisabled ||
              (paymentToken === BWJ_TOKEN && lowerThanClaimLimit) ||
              noRewards
            }
          >
            {isLoadingAnyState ? (
              <CircularProgress size={28} className="text-white" />
            ) : (
              <span className="inline-flex gap-x-2 items-center">
                <FormattedNumber
                  value={paymentToken === BWJ_TOKEN ? bwjAmount : wusdAmount}
                />
                <span>Claim All</span>
              </span>
            )}
          </PrimaryDarkButton>
        </div>
      </div>

      <TweetModal
        claimLoading={isLoadingAnyState}
        onClaimClick={onClaimClick}
        amount={bwjAmount}
        open={openTweetModal}
        onClose={() => setOpenTweetModal(false)}
      />
    </>
  );
}
